import React from 'react';
import { FaInstagram, FaFacebook } from 'react-icons/fa';

export default function Footer() {
  return (
    <footer id='footer'>
      <ul className='copyright'>
        <li>Kateřina Mužíková (Gregorová)</li>
        <li>
          <a href='mailto:muzik.katka@gmail.com'>muzik.katka@gmail.com</a>
        </li>
        <li>776 315 944</li>
      </ul>
      <ul className='icons'>
        <li>
          <a href='https://www.instagram.com/mkphoto_beskydy' className='icon' target='_blank' rel='noreferrer'>
            <FaInstagram />
          </a>
        </li>
        <li>
          <a href='https://www.facebook.com/MuzikovaKatkaPhoto' className='icon' target='_blank' rel='noreferrer'>
            <FaFacebook />
          </a>
        </li>
      </ul>
    </footer>
  );
}
