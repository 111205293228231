import React from 'react';
import { Link } from 'gatsby';
import { CgClose, CgMenu } from 'react-icons/cg';

export default function Nav({ onMenuToggle = () => {} }) {
  return (
    <nav id="nav">
      <ul>
        <li className="special">
          <a
            href="#menu"
            onClick={e => {
              e.preventDefault();
              onMenuToggle();
            }}
            className="menuToggle"
          >
            <CgMenu />
          </a>
          <div id="menu">
            <ul>
              <li>
                <Link to="/">Úvod</Link>
              </li>
              <li>
                GALERIE
                <ul>
                  <li>
                    <Link to="/rodinne">Rodinné focení</Link>
                  </li>
                  <li>
                    <Link to="/portrety">Portréty</Link>
                  </li>
                  <li>
                    <Link to="/svatby">Svatby</Link>
                  </li>
                  <li>
                    <Link to='/zvirata'>Focení zvířat</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/cenik">Ceník</Link>
              </li>
              <li>
                <Link to="/informace">Jak probíhá focení</Link>
              </li>
              <li>
                <Link to="/kontakt">Kontakt</Link>
              </li>
            </ul>
            <a
              className="close"
              onClick={e => {
                e.preventDefault();
                onMenuToggle();
              }}
              href="#menu"
            >
              <CgClose />
            </a>
          </div>
        </li>
      </ul>
    </nav>
  );
}
